import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { HugeTitle } from '@/components/lp/scalehack/for-marketing/hugeTitle'
import { mq } from '@/components/media/media'
import { FlowWideContainer } from './flowWideContainer'
import { FlowNarrowContainer } from './flowNarrowContainer'
import { useObserver } from '@/hooks/useObserber'
import { overMid } from '@/components/lp/scalehack/for-marketing/layout'

export const StepSection = () => {
  const { isDisplayed, ref } = useObserver<HTMLElement>(
    '-80% 0px -20%',
    false,
    false
  )

  return (
    <section css={section} ref={ref}>
      <div css={sectionInner}>
        <HugeTitle
          jpTitleText="ご相談からご支援の流れ"
          enTitleText="STEP"
          isDisplayed={isDisplayed}
        />
        <p css={lead}>
          貴社の課題やご要望を登録するだけで、経験豊富なハイクラス人材が
          <br css={overMid} />
          無料で解決策のご提案を致します。
        </p>
        <FlowWideContainer />
        <FlowNarrowContainer />
        <StaticImage
          css={image}
          src="../../../../../images/scalehack/for-marketing/company/start.svg"
          alt="プロジェクト開始"
        />
      </div>
    </section>
  )
}

const section = css`
  display: flex;
  justify-content: center;
  margin: 150px 0 140px;

  ${mq.midSHM} {
    margin: 66px 0;
  }
`

const sectionInner = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  margin: 0 16px;
`

const lead = css`
  margin-top: 23px;
  font-size: 1.6rem;
  font-weight: 300;
  text-align: center;

  ${mq.midSHM} {
    width: 86%;
    margin: 30px auto 0;
    font-size: 1.4rem;
  }
`

const image = css`
  margin-top: 42px;

  ${mq.midSHM} {
    width: 140px;
    height: 140px;
    margin-top: 32px;
  }
`
