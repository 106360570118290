import React from 'react'
import color from '@/components/lp/scalehack/color'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { mq } from '@/components/media/media'
import right_arrow from '@/images/scalehack/for-marketing/icon-right-arrow.svg'

export const FlowNarrowContainer = () => {
  return (
    <div css={flowNarrowContainer}>
      <div css={titleContainer}>
        <p css={title}>貴社</p>
        <div css={logo}>
          <StaticImage
            src="../../../../../images/scalehack/for-marketing/logo.png"
            alt="Scalehack for Marketing"
          />
        </div>
      </div>
      <ol css={list}>
        <li css={listClientItem}>貴社の課題をプロジェクトとして登録</li>
        <li css={listSHMItem}>
          ハイクラス人材による課題の深掘り・
          <br />
          ヒアリングを実施
        </li>
        <li css={listSHMItem}>解決策・ご提案実施（無料）</li>
        <li css={listClientItem}>提案内容のご検討</li>
        <li css={listClientItem}>内容に応じた契約締結</li>
      </ol>
    </div>
  )
}

const flowNarrowContainer = css`
  display: none;

  ${mq.midSHM} {
    display: block;
    margin-top: 47px;
  }
`

const titleContainer = css`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`

const title = css`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;

  &:before {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    content: '';
    background-color: ${color.grayF5};
    border-radius: 2px;
  }
`

const logo = css`
  position: relative;
  width: 76px;
  margin-left: calc(28px + 28px);

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -28px;
    display: block;
    width: 20px;
    height: 20px;
    margin: auto 0;
    content: '';
    background-color: ${color.orange7};
    border-radius: 2px;
  }
`
const list = css`
  position: relative;
  width: 276px;
  margin: 0 auto;
  counter-reset: number;

  &:before {
    position: absolute;
    top: 16px;
    right: 0;
    left: 0;
    z-index: -1;
    width: 1px;
    height: 100%;
    margin: 0 auto;
    content: '';
    border: 1px dashed ${color.brack888};
  }

  &:after {
    position: absolute;
    right: 0;
    bottom: -24px;
    left: 0;
    width: 12px;
    height: 12px;
    margin: 0 auto;
    content: '';
    background-image: url(${right_arrow});
    background-size: cover;
    transform: rotate(90deg);
  }
`

const listItem = css`
  display: flex;
  align-items: center;
  padding: 8px;
  margin-top: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 4px;

  &:before {
    padding-right: 10px;
    font-family: din-2014, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    content: counter(number, decimal-leading-zero);
    counter-increment: number;
  }
`

const listClientItem = css`
  ${listItem}
  background-color: ${color.grayF5};
`

const listSHMItem = css`
  ${listItem}
  background-color: ${color.orange7};
`
