import React from 'react'
import { css } from '@emotion/react'
import { HugeTitle } from '@/components/lp/scalehack/for-marketing/hugeTitle'
import { DashedCardList } from '@/components/lp/scalehack/for-marketing/company/dashedCardList'
import { mq } from '@/components/media/media'
import { issueListCompany } from '@/constants/scalehack/issueList'
import { useObserver } from '@/hooks/useObserber'
import schemaImg from '@/images/scalehack/for-marketing/schema.png'

export const IssueSection = () => {
  const { isDisplayed, ref } = useObserver<HTMLElement>(
    '-80% 0px -20%',
    false,
    false
  )

  return (
    <section css={section} ref={ref}>
      <div css={sectionInner}>
        <HugeTitle
          isDisplayed={isDisplayed}
          jpTitleText="こんな課題はありませんか？"
          enTitleText="ISSUE"
        />
        <DashedCardList issues={issueListCompany} />
        <p css={boldText}>
          その課題、Scalehack for Marketingが
          <br />
          解決します。
        </p>
        <div css={system}>
          <img css={systemImg} src={schemaImg} alt="" width="825" height="354" />
        </div>
      </div>
    </section>
  )
}

const section = css`
  display: flex;
  justify-content: center;
  margin: 110px 0 0;

  ${mq.midSHM} {
    margin: 66px 0 0;
  }
`

const sectionInner = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 260px);
  max-width: 1120px;

  ${mq.midSHM} {
    width: calc(100% - 40px);
  }
`

const boldText = css`
  margin-top: 64px;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;

  ${mq.midSHM} {
    margin: 40px 0 0;
    font-size: 1.6rem;
  }
`

const system = css`
  max-width: 825px;
  margin: 32px auto 0;

  ${mq.midSHM} {
    margin-top: 24px;
  }
`

const systemImg = css`
  height: auto;
`
