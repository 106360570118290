import React from 'react'
import color from '@/components/lp/scalehack/color'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from '@emotion/react'
import { mq } from '@/components/media/media'

export const FlowWideContainer = () => {
  return (
    <div css={flowWideContainer}>
      <div css={flowWideContainerItem}>
        <h3 css={flowWideContainerTitle}>貴社</h3>
        <ul css={flowWideContainerClientCardList}>
          <li css={flowWideContainerClientCard}>
            <div css={flowWideContainerNumber}>01</div>
            <div css={flowWideContainerText}>貴社の課題やご要望を登録</div>
          </li>
          <li css={flowWideContainerClientCard}>
            <div css={flowWideContainerNumber}>04</div>
            <div css={flowWideContainerText}>提案内容のご検討</div>
          </li>
          <li css={flowWideContainerClientCard}>
            <div css={flowWideContainerNumber}>05</div>
            <div css={flowWideContainerText}>内容に応じた契約締結</div>
          </li>
        </ul>
      </div>
      <div css={flowWideContainerItem}>
        <StaticImage
          css={flowWideContainerLogo}
          src="../../../../../images/scalehack/for-marketing/logo.png"
          alt="Scalehack for Marketing"
        />
        <ul css={flowWideContainerSHMCardList}>
          <li css={flowWideContainerSHMCard}>
            <div css={flowWideContainerNumber}>02</div>
            <div css={flowWideContainerText}>
              ハイクラス人材による課題の
              <br />
              深掘り・ヒアリングを実施
            </div>
          </li>
          <li css={flowWideContainerSHMCard}>
            <div css={flowWideContainerNumber}>03</div>
            <div css={flowWideContainerText}>解決策・ご提案実施（無料）</div>
          </li>
        </ul>
      </div>
    </div>
  )
}

const flowWideContainer = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 920px;
  margin-top: 80px;

  &:before {
    position: absolute;
    right: calc(50% + -1px);
    bottom: 2px;
    height: 446px;
    content: '';
    border: 1px dashed ${color.brack888};
  }

  &:after {
    position: absolute;
    right: calc(50% + -3px);
    bottom: 0;
    content: '';
    border-top: 6px solid ${color.brack888};
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
  }

  ${mq.midSHM} {
    display: none;
  }
`

const flowWideContainerItem = css`
  display: flex;
  flex-direction: column;
`

const flowWideContainerTitle = css`
  margin-bottom: 18px;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
`

const flowWideContainerLogo = css`
  width: 140px;
  margin: 0 auto 12px;
`

const flowWideContainerCardList = css`
  flex: 1;
  width: 370px;
  padding: 0 40px 40px 0;
  border-radius: 8px;
`

const flowWideContainerSHMCardList = css`
  ${flowWideContainerCardList}
  background-color: ${color.orange7};
`

const flowWideContainerClientCardList = css`
  ${flowWideContainerCardList}
  background-color: ${color.grayF5};
`

const flowWideContainerCard = css`
  display: flex;
  align-items: center;
  height: 80px;
  padding: 0 10px 0 24px;
  margin: 40px 0 0 40px;
  border: 1px dashed ${color.gray8b};
  border-radius: 4px;
`

const flowWideContainerClientCard = css`
  ${flowWideContainerCard}

  &:nth-of-type(2) {
    margin-top: 168px;
  }

  &:before {
    position: absolute;
    right: 50%;
    width: 128px;
    content: '';
    border: 1px dashed ${color.gray8b};
  }

  &:after {
    position: absolute;
    left: calc(50% + -3px);
    width: 6px;
    height: 6px;
    content: '';
    background-color: ${color.gray8b};
    border-radius: 50%;
  }
`

const flowWideContainerSHMCard = css`
  ${flowWideContainerCard}

  &:first-of-type {
    margin-top: 90px;
  }

  &:before {
    position: absolute;
    left: 50%;
    width: 128px;
    content: '';
    border: 1px dashed ${color.red1};
  }

  &:after {
    position: absolute;
    left: calc(50% + -3px);
    width: 6px;
    height: 6px;
    content: '';
    background-color: ${color.red1};
    border-radius: 50%;
  }
`

const flowWideContainerNumber = css`
  margin-right: 10px;
  font-family: din-2014, sans-serif;
  font-size: 2rem;
  font-weight: 700;
`

const flowWideContainerText = css`
  font-size: 1.6rem;
  font-weight: 600;
  white-space: pre-wrap;
`
