import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { StaticImage } from 'gatsby-plugin-image'
import { Issue } from '@/constants/scalehack/issueList'
import { mq } from '@/components/media/media'

type Props = {
  issues: Issue[]
}

export const DashedCardList = (props: Props) => {
  const { issues } = props

  return (
    <ul css={container}>
      {issues.map((issue, i) => (
        <li css={block} key={i}>
          <div>
            <StaticImage
              css={headerIcon}
              src="../../../../../images/scalehack/for-marketing/icon-exclamation-mark.svg"
              alt="!"
            />
            <h3 css={headerTitle}>{issue.title}</h3>
            <div css={headerEnTitle} aria-hidden="true">
              ISSUE
            </div>
          </div>
          <ul css={list}>
            {issue.text.map((text, j) => (
              <li css={listItem} key={j}>
                {text}
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}

const container = css`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 88px;
  text-align: center;

  ${mq.midSHM} {
    gap: 20px 24px;
    width: 100%;
    padding: 0 5%;
    margin-top: 45px;
  }
`

const block = css`
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  background-color: ${color.red2};

  ${mq.midSHM} {
    width: 100%;
    max-width: 320px;
    padding: 20px 30px;
  }
`

const headerIcon = css`
  width: 24px;
`

const headerTitle = css`
  margin-top: 15px;
  font-size: 1.6rem;
  font-weight: 600;

  ${mq.midSHM} {
    margin-top: 12px;
  }
`

const headerEnTitle = css`
  margin-top: 4px;
  font-family: din-2014, sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${color.brack888};
`

const list = css`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
`

const listItem = css`
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  min-height: 88px;
  padding: 10px 3px;
  font-weight: 600;
  color: ${color.gray4E};
  border: 1px dashed ${color.brackOpacity};
  border-radius: 8px;

  ${mq.midSHM} {
    font-size: 1.4rem;
  }
`
