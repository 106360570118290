import React from 'react'
import { Layout } from '@/components/lp/scalehack/for-marketing/layout'
import { Meta } from '@/components/lp/scalehack/for-marketing/meta'
import { IssueSection } from '@/components/lp/scalehack/for-marketing/company/issueSection'
import { ReasonSection } from '@/components/lp/scalehack/for-marketing/company/reasonSection'
import { SupportSection } from '@/components/lp/scalehack/for-marketing/company/supportSection'
import { StepSection } from '@/components/lp/scalehack/for-marketing/company/stepSection'
import {
  MessageSection,
  messageBoldText
} from '@/components/lp/scalehack/for-marketing/messageSection'
import {
  BannerSection,
  bannerBoldText
} from '@/components/lp/scalehack/for-marketing/company/bannerSection'
import { KeyVisual } from '@/components/lp/scalehack/for-marketing/company/keyVisual'
import { Header } from '@/components/lp/scalehack/for-marketing/company/header'
import { useObserver } from '@/hooks/useObserber'
import { FloatingActionButton } from '@/components/lp/scalehack/for-marketing/company/floatingActionButton'

export default () => {
  const { isDisplayed, ref } = useObserver<HTMLElement>('-50% 0px', true, true)

  return (
    <Layout>
      <Meta
        title="Scalehack for Marketing :ハイクラス人材が提供するマーケティング支援"
        description='Scalehack for Marketingは、"本物"のハイクラス人材が提供する的確で効率的なマーケティング支援サービスです。中途採用では採れない実務経験豊富なマーケティングプロフェッショナルが、事業フェーズや課題に合わせて的確なマーケティング支援を致します。'
        ogImg="https://relic-holdings.co.jp/OGP_1200*630_ScaleHack_Marketing_Corporate.png"
      />
      <Header />
      <KeyVisual ref={ref} />
      <FloatingActionButton isDisplayed={isDisplayed} />
      <IssueSection />
      <MessageSection>
        中途採用では採れない実務経験豊富なマーケティングプロフェッショナルが、
        <br />
        <span css={messageBoldText}>
          事業フェーズや課題に合わせて的確なマーケティング支援
        </span>
        を致します。
      </MessageSection>
      <ReasonSection />
      <SupportSection />
      <StepSection />
      <BannerSection>
        <span css={bannerBoldText}>
          課題の整理からマーケティング戦略の立案・具体的なKPI改善
        </span>
        まで、
        <br />
        どんなお悩みでもご相談ください。
      </BannerSection>
    </Layout>
  )
}
