import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { Button } from '@/components/lp/scalehack/for-marketing/button'
import { mq } from '@/components/media/media'

type Props = {
  isDisplayed: boolean
}

export const FloatingActionButton = (props: Props) => {
  const { isDisplayed } = props

  return (
    <div css={container(!isDisplayed)}>
      <div css={buttonWrapper}>
        <Button css={button} to="/group/scalehack/for-marketing/contact">
          無料で提案を依頼する
        </Button>
        <Button css={button} to="/group/scalehack/for-marketing/document">
          資料請求する
        </Button>
      </div>
    </div>
  )
}

const container = (isDisplayed: boolean) => css`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: none;
  width: 100%;
  padding: 10px 15px;
  background-color: ${color.white};

  ${mq.midSHM} {
    display: block;
  }

  ${isDisplayed
    ? css`
        visibility: visible;
        opacity: 1;
        transition: transform 0.4s ease;
        transform: translateY(0);
      `
    : css`
        visibility: hidden;
        opacity: 0;
        transition: transform 0.4s ease, opacity 0s 0.4s, visibility 0s 0.4s;
        transform: translateY(100%);
      `}
`

const buttonWrapper = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const button = css`
  margin: 6px;

  ${mq.midSHM} {
    width: fit-content;
    padding: 7px 20px;
    font-size: 1.4rem;
  }
`
