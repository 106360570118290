import React, { ReactNode } from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { Button } from '@/components/lp/scalehack/for-marketing/button'
import { mq } from '@/components/media/media'

type Props = {
  children: ReactNode
}

export const BannerSection = (props: Props) => {
  const { children } = props

  return (
    <section css={section}>
      <div css={sectionInner}>
        <p css={text}>{children}</p>
        <div css={buttonContainer}>
          <Button
            css={button}
            color="white"
            to="/group/scalehack/for-marketing/contact"
          >
            無料相談する
          </Button>
          <Button
            css={button}
            color="white"
            to="/group/scalehack/for-marketing/document"
          >
            資料請求する
          </Button>
        </div>
      </div>
    </section>
  )
}

const section = css`
  display: flex;
  justify-content: center;
  padding: 124px 40px;
  color: ${color.white};
  background: linear-gradient(
    100deg,
    ${color.orange8} 0%,
    ${color.orange9} 100%
  );

  ${mq.midSHM} {
    padding: 40px 20px;
  }
`

const sectionInner = css`
  width: calc(100% - 40px);
  max-width: 1120px;
  margin: 0 auto;
`

const text = css`
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 1.75;
  color: ${color.white};
  text-align: center;

  ${mq.midSHM} {
    font-size: 1.6rem;
  }
`

export const bannerBoldText = css`
  ${text}
  font-weight: 600;
`

const buttonContainer = css`
  display: flex;
  gap: 10px 16px;
  justify-content: center;
  margin-top: 38px;

  ${mq.midSHM} {
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`

const button = css`
  width: auto;
  ${mq.midSHM} {
    width: auto;
    min-width: 206px;
    padding-right: 40px;
    padding-left: 40px;
  }
`
