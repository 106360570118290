import React from 'react'
import { css } from '@emotion/react'
import { HugeTitle } from '@/components/lp/scalehack/for-marketing/hugeTitle'
import { useObserver } from '@/hooks/useObserber'
import { GradientCardList } from '../gradientCardList'
import { mq } from '@/components/media/media'

export const SupportSection = () => {
  const { isDisplayed, ref } = useObserver<HTMLElement>(
    '-80% 0px -20%',
    false,
    false
  )

  return (
    <section css={section} ref={ref}>
      <HugeTitle
        jpTitleText="ご支援の領域"
        enTitleText="SUPPORT"
        isDisplayed={isDisplayed}
      />
      <div css={sectionInner}>
        <GradientCardList css={cardWrapper} />
      </div>
    </section>
  )
}

const section = css``

const sectionInner = css`
  width: calc(100% - 260px);
  max-width: 1120px;
  margin: 0 auto;

  ${mq.midSHM} {
    width: calc(100% - 40px);
    max-width: 700px;
  }
`

const cardWrapper = css`
  margin-top: 70px;

  ${mq.midSHM} {
    margin-top: 40px;
  }
`
